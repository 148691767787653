.txu-toast-wrapper {
    @apply
        tw-hidden
        tw-flex-col
        tw-gap-1
        tw-fixed
        tw-top-[55px]
        tw-left-[10px]
        tw-z-[1001]
    ;

    &.txu-open {
        @apply tw-flex;
    }

    > .txu-toast {
        @apply
            tw-w-max
            tw-max-w-[600px]
            tw-m-0
            tw-mr-auto
            tw-my-3
            tw-p-4
            tw-border
            tw-rounded-lg
            tw-shadow-lg
            tw-flex
            tw-gap-8
            tw-items-start
        ;

        .txu-close {
            @apply tw-ml-auto tw-cursor-pointer tw-rounded tw-text-[1.3rem] hover:tw-outline;
        }
        
        > .txu-toast-content {
            @apply tw-whitespace-pre-line tw-m-[2px];

            ul {
                @apply tw-ms-4 tw-list-disc;
            }
        }

        &.info {
            @apply tw-border-blue-300 tw-bg-blue-50;
        }
    
        &.success {
            @apply tw-border-green-300 tw-bg-green-50;
        }
    
        &.warning {
            @apply tw-border-yellow-300 tw-bg-yellow-50;
        }
    
        &.error {
            @apply tw-border-red-300 tw-bg-red-50;
        }
    }
}

body:has(#navbar-control-checkbox:checked) {
    &:not(:has(dialog[open])) {
        .txu-toast-wrapper {
            @apply tw-left-[260px];
        }    
    }
}

body.embed {
    .txu-toast-wrapper {
        @apply !tw-left-[10px] !tw-top-[10px];
    }    
}
