.txu-modal {
    @apply tw-rounded-lg tw-shadow-lg max-[1200px]:tw-w-screen tw-overscroll-contain focus-visible:tw-outline-none tw-w-1/2;

    &.txu-lg {
        @apply tw-w-10/12;
    }
    &.txu-full {
        @apply tw-w-screen;
    }

    &.txu-loading {
        @apply tw-animate-pulse;

        & > .txu-content {
            @apply tw-invisible;
        }
    }

    & > .txu-content {
        @apply tw-flex tw-flex-col;

        & > .txu-header {
            @apply tw-px-8 tw-pt-8;
            &.txu-divide {
                @apply tw-border-b-2 tw-pb-4;
            } 
        }

        & > .txu-body {
            @apply tw-px-8 tw-py-6;
        }

        & > .txu-actions {
            @apply tw-sticky tw-bottom-0 tw-z-10 tw-flex tw-justify-end tw-gap-6 tw-bg-gray-50 tw-px-8 tw-py-4
        }
    }
}
