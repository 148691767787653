.txu-drag-over-padding {
    @apply !tw-pr-20 tw-transition-all tw-duration-200 tw-border-b-[1px] tw-border-gray-400 tw-mx-1;
}


.txu-no-code-editor {
    &[placeholder]:empty::before {
        content: attr(placeholder);
        @apply tw-text-gray-300;
    }
}
