.txu-alert {

    @apply tw-flex tw-items-center tw-gap-3 tw-border-x tw-border-b tw-border-red-500 tw-bg-red-500/10 tw-p-2 tw-text-red-500 first-of-type:tw-border-t last-of-type:tw-rounded-b last-of-type:tw-shadow-md;
    
    font-size: smaller;

    h1, h2, h3, h4, h5, h6, p {
        @apply tw-m-0;
    }

    h1 {
        @apply tw-text-xl;
    }
    h2 {
        @apply tw-text-lg;
    }
    h4 {
        @apply tw-text-sm;
    }
    h6 {
        @apply tw-text-xs;
    }
    
    .txu-close {
        @apply tw-ml-auto tw-cursor-pointer tw-rounded tw-text-[1.3rem] hover:tw-bg-red-200 hover:tw-outline;
    }

    &.txu-blue {
        @apply tw-border-blue-500 tw-bg-blue-500/10 tw-text-blue-500;
        .txu-close {
            @apply hover:tw-bg-blue-200;
        }
    }
    &.txu-yellow {
        @apply tw-border-yellow-500 tw-bg-yellow-500/10 tw-text-yellow-700;
        .txu-close {
            @apply hover:tw-bg-yellow-200;
        }
    }
}
