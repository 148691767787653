h1 {
    @apply tw-text-3xl;
}
h2 {
    @apply tw-text-2xl;
}
h3 {
    @apply tw-text-xl;
}
h4 {
    @apply tw-text-lg;
}
h6 {
    @apply tw-text-sm;
}
h1, h2, h3, h4, h5, h6 {
    @apply tw-m-2 tw-font-semibold;
}
h1, h2, h3, h4, h5, h6, p {
    @apply tw-m-2;
}
