.txu-paginator {
    @apply
      tw-flex
      tw-items-center
      tw-my-2
      tw-py-2
    ;

    > ul {
        @apply
            tw-flex-grow
            tw-text-center
            tw-flex
            tw-justify-center
        ;

        > li {

            > a {
                @apply
                    tw-no-underline
                    tw-py-2
                    tw-w-8
                    tw-block
                    tw-text-center
                    tw-text-gray-300
                    hover:tw-text-primary
                    active:tw-text-black
                ;

                &.disabled {
                    @apply
                        tw-opacity-40
                        tw-pointer-events-none
                      tw-text-gray-200
                    ;
                }

            }

            &.active {
                > a {
                    @apply
                        tw-text-primary
                        tw-border-b-2
                        tw-border-primary
                    ;
                }
            }
        }
    }
}
