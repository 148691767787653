.txu-settings-content-block {
    @apply 
        tw-bg-white 
        tw-rounded-md 
        tw-p-4 
        tw-border-2 
        tw-border-gray-200 
        tw-mt-4
        ;

    a.menu-option {
        @apply
            tw-text-gray-800
            tw-no-underline
            tw-transition-all
            tw-rounded-sm
        ;
        &:hover {
            @apply
                tw-bg-gray-100
                tw-ring-1
                tw-ring-gray-300

                ;
        }
    }
}


