

.txu-logs {
    @apply tw-m-10 tw-pb-20;

    h1 {
        @apply tw-m-0 tw-mb-8;
    }

    .txu-logs-filter-form {
        @apply tw-ml-2 tw-mb-8 tw-grid tw-auto-cols-fr tw-grid-flow-col tw-max-w-[700px] tw-gap-3;
    }


    .txu-logs-paginator {
        @apply tw-flex tw-gap-2 tw-justify-center tw-text-center tw-text-base tw-mb-2;

         
        &.txu-no-pages {
            @apply tw-invisible;
        }
        .txu-arrow {
            @apply -tw-mt-[2px];
            i {
                @apply tw-text-lg;
            }
            span {
                @apply tw-text-gray-300 tw-cursor-default;
            }
            a > i {
                @apply tw-transition-all hover:tw-scale-110;
            }
        }
        .txu-page-number {
            @apply tw-block tw-px-3 tw-pb-1 tw-border-b-[1px] tw-transition-all tw-no-underline;

            &.txu-current-page {
                @apply tw-text-primary tw-border-b-primary;
            }
            &:not(.txu-current-page) {
                @apply tw-text-gray-500 hover:tw-text-black tw-border-transparent hover:tw-border-b-black;
            }
        }
    }
    /* Add some space between the table and the bottom paginator */
    table + .txu-logs-paginator {
        @apply tw-mt-8 tw-mb-0;
    }
}


