details.txu-accordion {
    @apply
        tw-border-2
        tw-border-gray-200
        tw-rounded-md
        tw-p-2
        tw-my-4
    ;
    &:hover&:not([open]) {
        @apply
            tw-bg-gray-100
            tw-ring-1
            tw-ring-gray-300
        ;
    }
    &[open] {
        > summary {
            @apply
                tw-mb-4 /* Add some space between the summary and the content when open */
            ;
        }
    }
}

details > summary {
    @apply
        tw-cursor-pointer
        tw-ps-2
        tw-pe-2
    ;
}
