label.txu-label,
.txu-form label {
    @apply
        tw-font-semibold
        tw-block
        tw-mb-2
    ;
}

input[type=text].txu-input:not(.txu-dropdown-control), .txu-form input[type=text]:not(.txu-dropdown-control),
input[type=datetime-local].txu-input, .txu-form input[type=datetime-local],
input[type=date].txu-input, .txu-form input[type=date],
input[type=email].txu-input, .txu-form input[type=email],
input[type=number].txu-input, .txu-form input[type=number] {
    @apply
        tw-form-input
        tw-rounded-md
        tw-border-0
        tw-border-none
        tw-py-2
        tw-pr-8
        tw-text-gray-900
        tw-shadow-sm
        tw-ring-1
        tw-ring-inset
        tw-ring-gray-300
        focus:tw-ring-2
        focus:tw-ring-inset
      focus:tw-ring-gray-400
      disabled:tw-text-gray-400
      disabled:tw-ring-gray-100
    ;
    &:user-invalid {
        @apply
            tw-border-red-500
            tw-ring-red-500
            tw-ring-2
        ;
    }
}

input[type=checkbox].txu-input,
.txu-form input[type=checkbox] {
    @apply
        tw-form-checkbox
        tw-h-4
        tw-w-4
        tw-rounded
        tw-border-gray-400
        tw-text-textual-blue
        focus:tw-ring-gray-400
        disabled:tw-border-gray-200
        disabled:tw-text-gray-200
    ;

    + label {
        @apply
            tw-inline-block
            tw-mb-0
            tw-ms-2
            tw-align-middle
        ;
    }
}

input[type=radio].txu-input,
.txu-form input[type=radio] {
    @apply
        tw-form-radio
        tw-h-4
        tw-w-4
        tw-rounded
        tw-border-gray-400
        tw-text-textual-blue
        focus:tw-ring-gray-400
        disabled:tw-border-gray-200
        disabled:tw-text-gray-200
    ;

    + label {
        @apply
            tw-inline-block
            tw-mb-0
            tw-ms-2
            tw-align-middle
        ;
    }
}

input[type=file].txu-input,
.txu-form input[type=file] {
    @apply
        tw-form-input
        tw-rounded-md
        tw-border-0
        tw-p-2.5
        tw-text-gray-900
        tw-shadow-sm
        tw-ring-1
        tw-ring-inset
        tw-ring-gray-300
        focus:tw-ring-2
        focus:tw-ring-inset
      focus:tw-ring-gray-400
      disabled:tw-text-gray-400
      disabled:tw-ring-gray-100
    ;
}

select.txu-select,
.txu-form select {
    @apply
        tw-min-w-32
        tw-form-select
        tw-rounded-md
        tw-border-0
        tw-p-2.5
        tw-text-gray-900
        tw-shadow-sm
        tw-ring-1
        tw-ring-inset
        tw-ring-gray-300
        focus:tw-ring-2
        focus:tw-ring-inset
      focus:tw-ring-gray-400
      disabled:tw-text-gray-400
      disabled:tw-ring-gray-100
    ;
}

textarea.txu-textarea,
.txu-form textarea {
    @apply
        tw-form-textarea
        tw-rounded-md
        tw-border-0
        tw-p-2.5
        tw-text-gray-900
        tw-shadow-sm
        tw-ring-1
        tw-ring-inset
        tw-ring-gray-300
        focus:tw-ring-2
        focus:tw-ring-inset
      focus:tw-ring-gray-400
      disabled:tw-text-gray-400
      disabled:tw-ring-gray-100
    ;
}

label.txu-toggle {

    input[type="checkbox"] {
        @apply tw-sr-only;
    }

    @apply
        tw-cursor-pointer
        tw-block
        tw-h-6
        tw-w-10
        tw-rounded-full
        tw-bg-secondary
    ;

    &:after {
        content: '';
        @apply
            tw-relative
            tw-left-1
            tw-top-1 
            tw-flex
            tw-h-4
            tw-w-4
            tw-items-center
            tw-justify-center
            tw-rounded-full
            tw-bg-white
            tw-transition
        ;
    }

    &:has(input[type="checkbox"]:checked) {
        @apply
            tw-bg-primary
        ;
    }
    &:has(input[type="checkbox"]:checked):after {
        @apply
            tw-translate-x-full
        ;
    }

    &:has(input[type="checkbox"]:disabled) {
        @apply
            tw-bg-gray-300
        ;
    }
    &:has(input[type="checkbox"]:disabled):after {
        @apply
            tw-bg-gray-100
        ;
    }

}
