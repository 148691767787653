
.txu-navigation-wrapper {
    @apply tw-sticky tw-top-0 tw-z-50 tw-col-span-2 tw-row-start-1 tw-flex tw-items-center tw-gap-2 tw-border-b tw-bg-white tw-px-4;

    .txu-breadcrumbs {
        @apply tw-text-sm;
        .txu-breadcrumb {
            @apply hover:tw-underline;

            &:nth-last-child(-n + 1) {
                @apply tw-font-bold;
            }
        }



        .txu-breadcrumb-spacer {
            @apply tw-mx-1 tw-text-gray-400;

            &:not(:has(+ .txu-breadcrumb)) {
                @apply tw-hidden
            }
        }
    }
}
.txu-navigation-header {
    @apply tw-relative tw-flex tw-select-none tw-items-center tw-gap-6;
}

#navbar-control-checkbox:checked {
    + .txu-navigation-header .txu-hide-when-menu-open {
       @apply tw-hidden; 
    }


}

.txu-navbar-menu {
    @apply tw-fixed tw-top-0 tw-z-50 tw-col-span-1 tw-row-span-2 tw-row-start-1 tw-flex -tw-translate-x-full tw-flex-col tw-overflow-x-auto tw-border-r tw-bg-white tw-transition-transform tw-duration-200;
    height: 100vh;
    width: 250px;
    scrollbar-width: thin;

    .txu-navbar-seperator {
        @apply tw-mx-2 tw-my-1 tw-rounded-md tw-border-t-2 tw-border-gray-200;

        &:not(:has(+ .txu-navbar-item)) {
            @apply tw-hidden;
        }
    }

    .txu-navbar-item {
        @apply tw-flex tw-select-none tw-flex-col tw-px-1 tw-py-px tw-text-sm;
        
        a, label {
            @apply tw-flex tw-flex-1 tw-cursor-pointer tw-items-center tw-rounded-md tw-px-4 tw-py-2 hover:tw-bg-gray-100;

            i {
                @apply tw-text-gray-500; 
             }
            &.txu-active {
                @apply tw-relative tw-bg-gray-100;
                &::before {
                    content: "";
                    @apply tw-absolute tw-bottom-2 tw-left-1 tw-top-2 tw-rounded-md tw-border-l-[2px] tw-border-textual-blue;
                }
                i {
                    @apply tw-text-black; 
                 }
            }
        }

        .txu-sublist-icon-open {
            @apply tw-hidden;
        }
        .txu-toggable-sublist {
            @apply tw-h-0 tw-overflow-hidden tw-transition-all;
        }
        .txu-navbar-item {
            @apply tw-border-none tw-font-normal;
            a, label {
                @apply tw-border-none tw-pl-11;
            }
        }
        
        &:has(input[type="checkbox"]) {
            &:not(:has(input[type="checkbox"]:checked)) {
                &:has(a.txu-active), &:has(label.txu-active) {
                    a, label {
                        @apply tw-relative tw-bg-gray-100;

                        &::before {
                            content: "";
                            @apply tw-absolute tw-bottom-2 tw-left-1 tw-top-2 tw-rounded-md tw-border-l-[2px] tw-border-textual-blue;
                        }
                        i {
                            @apply tw-text-black; 
                         }
                    }
                }
            }
        }
        &:has(input[type="checkbox"]:checked) {
            .txu-sublist-icon-open {
                @apply tw-inline-block;
            }
            .txu-sublist-icon-closed {
                @apply tw-hidden;
            }
            .txu-toggable-sublist {
                @apply tw-mt-1 tw-h-auto;
            }
        } 
    }
}

.txu-settings-accordion {
    > :not(.txu-settings-accordion-controller) {
        @apply tw-invisible tw-h-0 tw-transition-[margin] tw-absolute;
    }
    &:has(input[name="navbar_settings"]:checked) {
        > *:not(.txu-settings-accordion-controller) {
            @apply tw-visible tw-static tw-mt-1 tw-h-auto;
        }
     }
}
.txu-navigation-wrapper:has(#navbar-control-checkbox:checked) + .txu-navbar-menu {
    @apply tw-translate-x-0 min-[1400px]:tw-sticky max-[1400px]:tw-shadow-2xl max-[1400px]:tw-shadow-gray-500;
}

.txu-main-content {
    @apply tw-col-span-2;
}

.txu-navigation-wrapper:has(#navbar-control-checkbox:checked) ~ .txu-main-content {
    @apply min-[1400px]:tw-col-span-1 tw-row-span-1 tw-row-start-2; 
}

.txu-navigation-wrapper:has(#navbar-control-checkbox:checked) {
    @apply tw-col-span-1 tw-col-start-2 tw-row-start-1; 
}


.embed {
    .txu-navigation-wrapper, .txu-navbar-menu {
        @apply !tw-hidden; 
    }

    .txu-main-content {
        @apply !tw-col-span-2 !tw-row-start-1;
    }
}






.txu-simple-menu {
    @apply tw-flex tw-flex-col;

    .txu-simple-menu-item {
         @apply tw-cursor-pointer tw-select-none tw-rounded-md tw-px-2 tw-py-1 tw-text-sm hover:tw-bg-gray-100;
     }
}
