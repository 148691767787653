.txu-log {
    @apply tw-p-8;

    &.txu-compact {
        @apply tw-p-4;
    }

    h1 {
        @apply tw-m-0 tw-mb-8;
    }

    .txu-log-item {
        @apply tw-grid tw-grid-cols-[250px,_1fr] tw-gap-y-10 tw-border-b-[1px] tw-border-gray-200 tw-py-8 tw-pl-2;
        * {
            @apply tw-m-0;
        }
        .txu-log-item-label {
            @apply tw-text-gray-600 tw-sticky tw-top-16 tw-left-0 tw-h-fit;
        }
        .txu-log-item-value {
            @apply tw-m-0;
        }
    }

    &.txu-compact {
        .txu-log-item {
            @apply tw-py-4;
        }
    }
}
