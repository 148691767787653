@import "tailwindcss/base";

@import "tailwindcss/components";
@import "./components/index.css";
@import "./views/index.css";
@import "tailwindcss/utilities";


@layer base {
    :root {

        /*
            Minimum Viewport Width: 1700, Minimum Font Size: 14px
            Maximum Viewport Width: 2600, Maximum Font Size: 16px
            resource: https://clamp.font-size.app
        */
        font-size: clamp(0.875rem, 0.6389rem + 0.2222vw, 1rem);
    }
    main a {
        @apply tw-text-textual-blue tw-underline;
        &.ui {
            text-decoration: none;
        }
    }

    body.embed main {
        height: max-content;
    }
}
