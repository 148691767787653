form.txu-metrics {
    #id_report_date_day {
        @apply tw-hidden;
    }

    .txu-form-filters {
        @apply tw-flex tw-gap-2 tw-mb-4;

        &.txu-small {
            @apply tw-text-xs;
            
            select {
                @apply tw-text-xs;
            }
        }
    }

    

}



