.txu-manage-container {
    @apply 
        tw-flex
        tw-flex-col
        tw-items-start
        tw-justify-center
        tw-pt-4
        tw-px-28
        ;

    > .txu-manage-group {
        @apply
            tw-pb-8
            tw-w-full
            ;

        section {
            @apply 
            tw-grid
            tw-grid-cols-[repeat(auto-fill,minmax(500px,1fr))]
            tw-gap-2
            ;
        }

        a, span {
            @apply
                tw-text-gray-800
                tw-no-underline
                tw-min-h-48
                tw-p-2
                tw-bg-white
                tw-border-gray-200
                tw-border-2
                tw-rounded-sm
                tw-transition-all
            ;
            &:hover {
                @apply
                    tw-bg-gray-100
                    tw-ring-2
                    tw-ring-gray-300
                    tw-ring-offset-1
                    ;
            }
        }
    }

    .txu-block-description {
        @apply tw-text-sm tw-text-gray-500;
    }

    .txu-number-input {
        input {
            @apply tw-w-4/5;
        }
    }
}



