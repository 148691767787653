 /* Here we can place custom font-sizes and stylings for HTML inside text patterns */
 .generated-text, .preview-item {
    * {
      @apply !tw-leading-[1.2] tw-m-0;
    }
    
    ul {
      list-style: disc;
    }
    ol {
      list-style: decimal;
    }
  
    ul, ol {
      margin-block: 1em;
      padding-left: 40px;
    }

    h1, h2, h3, h4, h5, h6 {
      @apply tw-mb-1;
    }
}
