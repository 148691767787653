.txu-invoice {
    @apply tw-ring-1 tw-ring-gray-200 tw-rounded-lg tw-p-4 tw-grid tw-grid-cols-2 tw-gap-6;

    .txu-invoice-section {
        @apply tw-ring-1 tw-ring-gray-500 tw-rounded-md tw-p-2;

        &.txu-full {
            @apply tw-col-span-2;
        }

        .txu-invoice-section-title {
            @apply tw-text-sm tw-text-gray-500;
        }

        .txu-invoice-section-summary {
            @apply tw-flex tw-flex-col tw-gap-2 tw-mt-4;

            .txu-invoice-section-summary-item {
                @apply tw-text-sm tw-ml-auto;
            }
        }
        
    }

}

