.txu-btn {
    @apply
        tw-inline-block
        tw-rounded
        tw-border
        tw-border-transparent
        tw-px-6
        tw-py-2
        tw-text-base  /* size */
        tw-font-medium  /* weight */
        tw-no-underline
        tw-transition-all
        enabled:hover:tw-shadow-md 
        enabled:hover:tw-brightness-110
    ;

    &:not(button) {
        @apply hover:tw-shadow-md hover:tw-brightness-110
    }

    i.material-icons {
        @apply tw-text-xl;
        line-height: inherit !important;
    }
    &.txu-primary {
        @apply 
           tw-border tw-border-primary tw-bg-primary tw-text-white;
    }
    &.txu-primary-alt {
        @apply 
            tw-border tw-border-primary tw-bg-white tw-text-primary enabled:hover:tw-bg-primary enabled:hover:tw-text-white;
        &:not(button) {
            @apply hover:tw-bg-primary hover:tw-text-white
        }
    }
    &.txu-secondary {
        @apply 
           tw-border tw-border-secondary tw-bg-secondary tw-text-white;
    }
    &.txu-secondary-alt {
        @apply 
            tw-border tw-border-secondary tw-bg-white tw-text-secondary enabled:hover:tw-bg-secondary enabled:hover:tw-text-white;
            &:not(button) {
                @apply hover:tw-bg-secondary hover:tw-text-white
            }
    }

    &.txu-textual-blue {
        @apply 
           tw-border tw-border-textual-blue tw-bg-textual-blue tw-text-white;
    }
    &.txu-textual-blue-alt {
        @apply 
            tw-border tw-border-textual-blue tw-bg-white tw-text-textual-blue enabled:hover:tw-bg-textual-blue enabled:hover:tw-text-white;
            &:not(button) {
                @apply hover:tw-bg-textual-blue hover:tw-text-white
            }

    }
    &.txu-lg {
        @apply tw-text-lg;
        i.material-icons {
            @apply tw-text-2xl
        }
    }

    &.txu-sm {
        @apply tw-text-sm;
        i.material-icons {
            @apply tw-text-base
        }
    }

    &.txu-icon {
        @apply tw-inline-flex tw-items-center tw-gap-2 tw-px-5;

    }

    &.txu-compact {
        @apply tw-px-1.5 tw-py-1;
    }

    &.txu-compact-very {
        @apply tw-px-1 tw-py-0.5;
        &.txu-icon {
            @apply tw-gap-[2px] tw-pl-0;
        }
    }

    &.txu-no-border {
        @apply !tw-border-transparent
    }

    &.txu-loading {
        @apply tw-relative tw-text-transparent !tw-cursor-default;
        
        &:after {
            content: "";
            @apply tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-m-auto tw-inline-block tw-h-5 tw-w-5 tw-animate-[spin_0.8s_linear_infinite] tw-rounded-full tw-border-2 tw-border-b-gray-400 tw-border-l-gray-900 tw-border-r-gray-400 tw-border-t-gray-400;
        }
    
        &.txu-primary::after, &.txu-secondary::after {
            @apply tw-border-l-white;
        }
    
    }
    
    &[disabled]:not(.txu-loading) {
        @apply tw-opacity-45;
    }


}

.txu-button-group:has(.txu-btn.txu-loading), .txu-button-group.txu-loading {
    @apply tw-relative tw-text-transparent !tw-cursor-default;
    
    &:after {
        content: "";
        @apply tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-m-auto tw-inline-block tw-h-5 tw-w-5 tw-animate-[spin_0.8s_linear_infinite] tw-rounded-full tw-border-2 tw-border-b-gray-400 tw-border-l-gray-900 tw-border-r-gray-400 tw-border-t-gray-400;
    }

    &:has(.txu-primary)::after, &:has(.txu-secondary)::after {
        @apply tw-border-l-white;
    }

    * {
        @apply tw-text-transparent;
        &::after {
            @apply tw-border-none;
        }
    }
}



