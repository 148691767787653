.txu-tab {
  > nav {
    @apply tw-flex tw-border-b-2 tw-border-gray-200 tw-mb-3;

    > .item {
      @apply tw-flex tw-gap-3 tw-px-5 tw-py-3;
      @apply tw-text-gray-500 tw-no-underline;

      &:hover {
        @apply tw-text-gray-800 tw-cursor-pointer;
      }

      &.active {
        @apply tw-text-black tw-font-semibold;
        @apply tw-border-black tw-border-b-2 tw-mb-[-2px];
      }
    }
  }
  > section {
    @apply tw-p-4;
  }
}
