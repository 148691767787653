.txu-table {
    @apply tw-min-w-full tw-text-left;
    thead {
        @apply tw-border-b-2 tw-border-neutral-200;

        th {
            @apply tw-font-semibold;
        }
    }
    tbody > tr {
        @apply tw-border-b tw-border-neutral-200;
    }
    th, td {
        @apply tw-px-4 tw-py-6;

        &.txu-hideable {
            @apply tw-hidden @[1500px]/main:tw-table-cell;
        }

        &.txu-truncatable {
            > * {
                @apply @[1500px]/main:tw-w-auto @[1500px]/main:tw-whitespace-normal tw-inline-block tw-w-28 tw-truncate;
            }
        }

        &.txu-left {
           @apply !tw-m-0 !tw-text-left;  
        }
        &.txu-right {
            @apply !tw-ml-auto !tw-text-right;  
         }
        &.txu-center {
           @apply !tw-mx-auto !tw-text-center  
        }
    }

    th {
        a {
            @apply tw-text-inherit;
        }
    }

    td {
        @apply tw-text-sm;
    }
    
    a {
        @apply tw-no-underline;
    }
    
    &.txu-compact {
        th, td {
            @apply tw-py-4;
        }
    }

    &.txu-very-compact {
        th, td {
            @apply tw-py-1.5;
        }
    }

    &.txu-small-text {
        th, td {
            @apply tw-text-xs;
        }
    }

    &.txu-no-padding {
        th, td {
            @apply tw-py-0;
        }
    }

    &.txu-border {
        td {
            @apply tw-border-b tw-border-l tw-border-r;
        }
    }

    &.txu-center-all {
        * {
            @apply tw-mx-auto tw-text-center;
        }
    }

    &.txu-clickable-rows {
        tbody > tr {
            @apply tw-cursor-pointer hover:tw-bg-gray-100/60 hover:tw-shadow;
        }
    }
}
