.txu-dropdown {
    @apply 
        tw-relative tw-w-auto tw-cursor-pointer tw-font-medium tw-text-secondary hover:tw-text-secondary/90;
    &.txu-upwards {
        .txu-dropdown-menu {
            @apply tw-flex-col-reverse tw-bottom-[100%];
        }
    }
    .txu-dropdown-button {
        @apply 
            tw-flex tw-gap-1 tw-rounded tw-border tw-border-secondary tw-px-4 tw-py-2;
    }
    .txu-dropdown-menu {
        @apply 
            tw-absolute 
            tw-left-0 
            tw-right-0 
            tw-rounded 
            tw-hidden 
            tw-flex-col 
            tw-drop-shadow-[0_1px_4px_rgba(0,0,0,0.25)] 
            tw-bg-palette-smoke 
            tw-w-max 
            tw-z-10;
        .txu-dropdown-item {
            @apply 
                tw-border-b tw-p-4 tw-transition-all hover:tw-bg-gray-200 hover:tw-shadow-sm;
        }
    }
    &:hover, &:active {
        .txu-dropdown-menu {
            @apply tw-flex
        }
    }
}
